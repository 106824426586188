import React, { useState, useEffect } from 'react';
import { Box, Tabs, Tab, Typography, CircularProgress } from '@mui/material'; // Import CircularProgress
import { useParams, useLocation } from 'react-router-dom';
import BreadcrumbsNav from '../components/BreadcrumbsNav'; 
import EntityDetails from '../components/Entity/EntityDetails';
import TransactionsOverTime from '../components/Entity/TransactionsOverTime';
import ResultsByGeography from '../components/Entity/ResultsByGeography';
import Awards from '../components/Entity/Awards';
import { getUsaRecipientDetails } from '../networking/entity/entityCalls'; // Import the query function

const Entity = () => {
  const location = useLocation();
  const { recipientID } = useParams();
  const { recipientName } = location.state || {};

  const [tabValue, setTabValue] = useState(0);
  const [recipientData, setRecipientData] = useState(null); // State to store fetched data
  const [loading, setLoading] = useState(true); // Loading state

  // Fetch recipient data when the component mounts
  useEffect(() => {
    const fetchRecipientData = async () => {
      try {
        const data = await getUsaRecipientDetails(recipientID); // Query for data
        // console.log(JSON.stringify(data));
        setRecipientData(data); // Store fetched data in state
      } catch (error) {
        console.error('Error fetching recipient data:', error);
      } finally {
        setLoading(false); // Stop loading once data is fetched
      }
    };

    if (recipientID) {
      fetchRecipientData();
    }
  }, [recipientID]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // Display spinner while loading
  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '50vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3 }}>
      <BreadcrumbsNav />
      <Typography variant="h4" gutterBottom>
        {recipientName}
      </Typography>
      <Tabs value={tabValue} onChange={handleTabChange} aria-label="entity tabs">
        <Tab label="Entity" />
        <Tab label="Transactions Over Time" />
        <Tab label="Results by Geography" />
        <Tab label="Awards" />
      </Tabs>
      <Box sx={{ mt: 3 }}>
        {tabValue === 0 && <EntityDetails recipientName={recipientName} recipientData={recipientData} />}
        {tabValue === 1 && <TransactionsOverTime recipientData={recipientData} />}
        {tabValue === 2 && <ResultsByGeography recipientData={recipientData} />}
        {tabValue === 3 && <Awards recipientData={recipientData} />}
      </Box>
    </Box>
  );
};

export default Entity;