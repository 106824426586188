import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import { Link } from 'react-router-dom';
import logo from '../quantum_logo.png'; // Adjust the path if necessary
import RightDrawer from './RightDrawer'; // Import the RightDrawer component

const Header = () => {
  return (
    <AppBar position="static" sx={{ backgroundColor: '#00274D' }}>
      <Toolbar>
        <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
          <Link to="/" style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center' }}>
            <img src={logo} alt="Quantum Logo" style={{ height: '40px', borderRadius: '5px', marginRight: '10px' }} />
            <Typography variant="h6">
              Quantum Research
            </Typography>
          </Link>
        </Box>
        {/* Replace IconButton with RightDrawer */}
        <RightDrawer />
      </Toolbar>
    </AppBar>
  );
};

export default Header;